import ResourcesAPI from '@bigml/bmlapp/src/core/api/resources'

import { getAPIHost } from '@/core/helpers/env'

import interceptors from '../interceptors'

class User extends ResourcesAPI {
  constructor() {
    super(getAPIHost(), interceptors, '/auth/users/')
  }
  async me() {
    return await this.request.get(`${this.path}me/`)
  }
}

export default new User()
