import { version } from '../../../package.json'

const {
  NODE_ENV = 'development',
  VUE_APP_API_BASE_PATH = '',
  VUE_APP_API_HOST = '',
  VUE_APP_APPLICATION_ID = null,
  VUE_APP_BASE_URL = '/',
  VUE_APP_I18N_FALLBACK_LOCALE = '',
  VUE_APP_I18N_LOCALE = '',
  VUE_APP_SECURE_COOKIE = false,
  VUE_APP_SECURE_HOST = false,
  VUE_APP_TITLE = ''
} = process.env

const ENVIRONMENT = NODE_ENV
const APP_TITLE = VUE_APP_TITLE
const BASE_URL = VUE_APP_BASE_URL || '/'
const APP_ID = VUE_APP_APPLICATION_ID || null
const API_HOST = VUE_APP_API_HOST || 'localhost:8080'
const API_BASE_PATH = VUE_APP_API_BASE_PATH || ''
const APP_VERSION = version || '0.0.0'

const SECURE_HOST =
  (VUE_APP_SECURE_HOST && ['1', 'true'].includes(String(VUE_APP_SECURE_HOST))) || false
const SECURE_COOKIE =
  (VUE_APP_SECURE_COOKIE && ['1', 'true'].includes(String(VUE_APP_SECURE_COOKIE))) ||
  false

const API_URL = `http${SECURE_HOST ? 's' : ''}://${API_HOST}${API_BASE_PATH}`

const LOCALE = VUE_APP_I18N_LOCALE || 'en'
const LOCALE_FALLBACK = VUE_APP_I18N_FALLBACK_LOCALE || 'en'

const STORAGE_TYPE = {
  COOKIE: 'COOKIE',
  LOCAL_STORAGE: 'LOCALSTORAGE'
}

const DEFAULT_DATETIME = 'yyyy-MM-dd HH:mm'
const DEFAULT_ITEMS_PER_PAGE = 6

export {
  API_BASE_PATH,
  API_HOST,
  API_URL,
  APP_ID,
  APP_TITLE,
  APP_VERSION,
  BASE_URL,
  DEFAULT_DATETIME,
  DEFAULT_ITEMS_PER_PAGE,
  ENVIRONMENT,
  LOCALE,
  LOCALE_FALLBACK,
  SECURE_COOKIE,
  SECURE_HOST,
  STORAGE_TYPE
}
