import i18n from '@/plugins/i18n'

const items = [
  { title: i18n.t('menu.tool'), to: { name: 'tool' }, icon: 'mdi-map-search' },
  {
    title: i18n.t('menu.new'),
    to: { name: 'new-project' },
    icon: 'mdi-pencil-box-outline'
  },
  {
    title: i18n.t('menu.search'),
    to: { name: 'search' },
    icon: 'mdi-card-search-outline'
  }
]

export { items }
