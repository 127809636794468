<template>
  <v-select
    class="mb-0 pb-0"
    dense
    :items="items"
    :label="label"
    outlined
    :item-value="country ? 'name' : 'value'"
    :value="value"
    :menu-props="{ bottom: true, offsetY: true }"
    @input="v => $emit('input', v)"
  >
    <template #item="slotProps" v-if="country">
      <v-icon class="mr-1" v-if="slotProps.item.flag == 'xx'">mdi-earth</v-icon>
      <flag class="mr-2" :iso="slotProps.item.flag" v-else />
      {{ slotProps.item.name }}
    </template>

    <template #selection="{ item, _ }" v-if="country">
      <v-icon class="mr-1" v-if="item.flag == 'xx'">mdi-earth</v-icon>
      <flag class="mr-2" :iso="item.flag" v-else />
      {{ item.name }}
    </template>
  </v-select>
</template>
<script>
import countries from '@/core/config/countries.js'

export default {
  name: 'DropdownFilter',
  props: {
    value: {
      validator: prop => typeof prop === 'string' || prop === null,
      required: true
    },
    status: {
      type: Boolean,
      default: false
    },
    country: {
      type: Boolean,
      default: false
    },
    publicview: {
      type: Boolean,
      default: false
    },
    source: {
      type: Boolean,
      default: false
    },
    fromform: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    label() {
      if (this.status) {
        return 'Publish Status'
      } else if (this.country) {
        return 'Country'
      } else if (this.source) {
        return 'Project source'
      }
      return ''
    },
    items() {
      if (this.status) {
        return [
          { text: 'All statuses', value: null },
          { text: 'Pending review', value: 'PENDING' },
          { text: 'Published', value: 'PUBLISHED' },
          { text: 'Model discarded', value: 'MODEL_DISCARDED' },
          { text: 'Rejected', value: 'REJECTED' }
        ]
      } else if (this.country) {
        let omit = ['USA', 'UNKNOWN', 'OTHER']
        if (!this.fromform) {
          countries.unshift({ name: 'All countries', flag: 'xx' })
        }
        if (this.publicview) {
          return countries.filter(i => !omit.includes(i.name))
        }
        return countries
      } else if (this.source) {
        return [
          { text: 'All sources', value: null },
          { text: 'Online form', value: 'ONLINE_FORM' },
          { text: 'Sacces', value: 'SACCES' },
          { text: 'Cordis', value: 'CORDIS' },
          { text: 'Chafea', value: 'CHAFEA' }
        ]
      }
      return []
    }
  },
  methods: {
    resetDropdown() {
      this.$emit('input', null)
    }
  }
}
</script>
<style lang="scss" scoped>
.v-select {
  height: 45px !important;
}
</style>
