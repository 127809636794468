import axios from 'axios'

import i18n from '@/plugins/i18n'
import router from '@/router'
import store from '@/store'

const BLACKLIST = ['/auth/token/', '/auth/token/verify/', '/auth/token/refresh/']

export default [
  response => response,
  async function (err) {
    const { status = null } = err?.response || {}
    if (status === 401) {
      const { config } = err
      const { __isRetryRequest, url } = config
      if (!BLACKLIST.includes(url)) {
        if (!__isRetryRequest) {
          await store.dispatch('auth/refreshTokens', true)
          const newConfig = { ...config, __isRetryRequest: true }
          return setTimeout(() => axios(newConfig), 300)
        }
        store.dispatch('alert/error', i18n.t('error.auth.not-valid'))
        return router.push({ name: 'auth.logout' })
      }
    }
    // Unknown error
    return Promise.reject(err)
  }
]
