export default [
  {
    name: 'AUSTRIA',
    flag: 'at'
  },
  {
    name: 'BELGIUM',
    flag: 'be'
  },
  {
    name: 'BULGARIA',
    flag: 'bg'
  },
  {
    name: 'CROATIA',
    flag: 'hr'
  },
  {
    name: 'CYPRUS',
    flag: 'cy'
  },
  {
    name: 'CZECH REPUBLIC',
    flag: 'cz'
  },
  {
    name: 'CZECHIA',
    flag: 'cz'
  },
  {
    name: 'DENMARK',
    flag: 'dk'
  },
  {
    name: 'ESTONIA',
    flag: 'ee'
  },
  {
    name: 'FINLAND',
    flag: 'fi'
  },
  {
    name: 'FRANCE',
    flag: 'fr'
  },
  {
    name: 'GERMANY',
    flag: 'de'
  },
  {
    name: 'GREECE',
    flag: 'gr'
  },
  {
    name: 'HUNGARY',
    flag: 'hu'
  },
  {
    name: 'ICELAND',
    flag: 'is'
  },
  {
    name: 'IRELAND',
    flag: 'ie'
  },
  {
    name: 'ITALY',
    flag: 'it'
  },
  {
    name: 'LATVIA',
    flag: 'lv'
  },
  {
    name: 'LITHUANIA',
    flag: 'lt'
  },
  {
    name: 'LUXEMBOURG',
    flag: 'lu'
  },
  {
    name: 'MALTA',
    flag: 'mt'
  },
  {
    name: 'NETHERLANDS',
    flag: 'nl'
  },
  {
    name: 'NORWAY',
    flag: 'no'
  },
  {
    name: 'POLAND',
    flag: 'pl'
  },
  {
    name: 'PORTUGAL',
    flag: 'pt'
  },
  {
    name: 'ROMANIA',
    flag: 'ro'
  },
  {
    name: 'SLOVAKIA',
    flag: 'sk'
  },
  {
    name: 'SLOVENIA',
    flag: 'si'
  },
  {
    name: 'SPAIN',
    flag: 'es'
  },
  {
    name: 'SWEDEN',
    flag: 'se'
  },
  {
    name: 'SWITZERLAND',
    flag: 'ch'
  },
  {
    name: 'UK',
    flag: 'gb'
  },
  {
    name: 'USA',
    flag: 'us'
  },
  {
    name: 'OTHER',
    flag: 'xx'
  }
]
