import { API_BASE_PATH, API_HOST, ENVIRONMENT, SECURE_HOST } from '@/core/config'

/**
 * Retrive API URL
 * @returns {string}
 */
export function getAPIHost() {
  return `http${SECURE_HOST ? 's' : ''}://${API_HOST}${API_BASE_PATH}`
}

/**
 * Check if environment is "development"
 * @returns {boolean}
 */
export function isDevelopment() {
  return ENVIRONMENT === 'development'
}
