import ResourcesAPI from '@bigml/bmlapp/src/core/api/resources'

import { getAPIHost } from '@/core/helpers/env'

import interceptors from '../interceptors'

const BASE_PATH = '/data-entities/project-public/'

class PublicProject extends ResourcesAPI {
  constructor() {
    super(getAPIHost(), interceptors, BASE_PATH)
  }
}

export default new PublicProject()
