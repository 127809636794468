import '@/assets/scss/main.scss'
import './plugins/leaflet'
import './plugins/bmlapp'
import './plugins/vueflag'

import { DateTime, Settings } from 'luxon'
import Vue from 'vue'

import App from './App.vue'
import { LOCALE } from './core/config'
import i18n from './plugins/i18n'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

// Luxon config
Settings.defaultLocale = LOCALE
Settings.defaultZoneName = DateTime.local().zoneName

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
