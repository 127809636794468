import API from '@bigml/bmlapp/src/core/api/api'

import { getAPIHost } from '@/core/helpers/env'

import interceptors from '../interceptors'

class Auth extends API {
  constructor() {
    super(getAPIHost(), interceptors)
  }
  async token(username, password) {
    const query = { username, password }
    const { data = null } = await this.request.post('/auth/token/', query)
    return data
  }
  async refreshTokens(refresh) {
    const { data = null } = await this.request.post('/auth/token/refresh/', { refresh })
    return data
  }
  async verifyToken(token) {
    const { data = null } = await this.request.post('/auth/token/verify/', { token })
    return data
  }
}

export default new Auth()
