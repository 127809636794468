<template>
  <v-card
    class="d-flex justify-center project-filter"
    :class="small ? 'size-small' : 'size-big'"
  >
    <v-avatar left :class="small ? 'size-small' : 'size-big'" v-if="isCategory">
      <v-img
        :height="small ? 16 : 20"
        :src="require(`@/assets/img/${img}`)"
        contain
      ></v-img>
    </v-avatar>
    <v-checkbox
      class="text-center"
      :class="small ? 'checkbox-small' : 'checkbox-big'"
      :input-value="value"
      :aria-label="(value ? 'Deactivate ' : 'Activate ') + textToShow"
      :dark="!isCategory"
      right
      @change="onClick"
    >
      <template #label>
        <span style="color: rgba(0, 0, 0, 0.87)">
          {{ small ? textToShow : textToShow.toUpperCase() }}
        </span>
      </template>
    </v-checkbox>
  </v-card>
</template>
<script>
const CATEGORIES = {
  ELEARNING: {
    name: 'e-Learning',
    img: 'elearning.png'
  },
  TELEWORK: {
    name: 'Telework',
    img: 'telework.png'
  },
  TELECARE: {
    name: 'Telecare',
    img: 'telecare.png'
  },
  OTHER: {
    name: 'Other',
    img: 'category_other.png'
  },
  UNKNOWN: {
    name: 'Unknwon',
    img: 'category_other.png'
  }
}
export default {
  name: 'ProjectFilter',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    category: {
      type: String,
      required: false,
      default: ''
    },
    text: {
      type: String,
      required: false,
      default: ''
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isCategory: this.category != '',
      textToShow: this.category != '' ? CATEGORIES[this.category].name : this.text,
      img: this.category != '' ? CATEGORIES[this.category].img : ''
    }
  },
  methods: {
    onClick() {
      this.$emit('input', !this.value)
    }
  }
}
</script>
<style lang="scss" scoped>
.project-filter {
  margin: 0;
  padding: 0;
  background-color: #fff0 !important;
  border: 1px solid;
}

.size-big {
  height: 40px !important;
}

.size-small {
  height: 35px !important;
}

.checkbox-small {
  margin-top: 0;
  font-weight: 500;
  font-size: 11px !important;
}

.checkbox-big {
  margin-top: 4px;
  font-weight: 500;
  font-size: 14px !important;
}

.check {
  pointer-events: none;
}
</style>
